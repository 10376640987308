@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #fff;
  --item-border: #e2e3e7;
  --plate-border: #e2e3e7;
  --item-background: #fff;
  --item-disabled-background: #f6f6f6;
  --item-secondary-background: #efefef;
  --dropdown-search-background: #f8f9fa;
  --section-border: #e2e3e7;
  --main-text: #111314;
  --secondary-text: #64686f;
  --border-secondary: #f3f3f3;
  --scrollbar-color: rgb(17 19 20 / 40%);
  --widget-border: #5f4cd1;
  --safari-scroll-background: #fff;

  //intercom color
  --intercom-color: rgb(17 19 20 / 40%);

  //menu text colors
  --menu-item: #c3c3c5;
  --menu-item-hover: #fff;
  --menu-icon: brightness(0) saturate(100%) invert(86%) sepia(0%) saturate(230%) hue-rotate(285deg) brightness(92%) contrast(92%);
  --menu-icon-hover: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(0%) hue-rotate(161deg) brightness(112%) contrast(100%);

  //icons
  --icon-background: #5f4cd1;
  --icon-color: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(0%) hue-rotate(161deg) brightness(112%) contrast(100%);
  --grey-text-icons: #767676;
  --icon-danger-background: #cc3030;
  --icon-danger-color: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(128deg) brightness(100%) contrast(105%);
  --icon-inactive: #a7a7a7;
  --hover-filter: invert(32%) sepia(89%) saturate(1098%) hue-rotate(225deg) brightness(84%) contrast(96%);

  //wallet types
  --fiat-type: #1894da;
  --crypto-type: #5f4cd1;
  --saving-type: #510c76;
  --market-chart: #5f4cd1;

  //cards
  --card-text: #fff;
  --card-text-title: rgb(#{hex-to-rgb(#fff)} / 60%);
  --card-cvv: #111314;
  --logo-width: 12.9em; // only in em
  --fields-card: unset;
  --card-logo: unset;
  --card-background: #5f4cd1;

  // app colors
  --hint-background: #f5edff;
  --notification-background: #eef4ff;
  --gradient-color1: #5f4cd1;
  --gradient-color2: #510c76;
  --main-red: #cc3030;
  --grey: #efefef;
  --grey-background: #f6f6f6;
  --grey-easy: #d9d9d9;
  --grey-primary: #888989;
  --light-red: #f9e6e6;
  --light-orange: #fbefe1;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #e5f5ed;
  --light-blue: #1e55b2;
  --orange: #e17c05;
  --hover-primary: #503fb4;
  --extra-light: #f8f6ff;
  --primary-text: #5f4cd1;
  --button-primary: #5f4cd1;
  --other-blue: #1e55b2;
  --separator-color: #979797;

  // gradients
  --primary-gradient: linear-gradient(136.32deg, var(--gradient-color1) 0%, var(--gradient-color2) 97.8%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(31%) sepia(69%) saturate(1871%) hue-rotate(232deg) brightness(84%) contrast(94%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  //sizes
  --auth-logo-height: 41px;
  --dashboard-logo-width: 152px;
  --dashboard-logo-mobile-width: 45.43px;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}

@media (max-width: $table-sm-breakpoint) {
  :root {
    --menu-item-hover: #111314;
    --menu-item: #5f4cd1;
    --menu-icon: brightness(0) saturate(100%) invert(35%) sepia(31%) saturate(3320%) hue-rotate(229deg) brightness(83%) contrast(97%);
    --menu-icon-hover: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);
  }
}
